import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

const Success = () => {
  const [transactionDetails, setTransactionDetails] = useState(null);
  const location = useLocation();

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const sessionId = query.get("session_id");

    if (sessionId) {
      fetchTransactionDetails(sessionId);
    }
  }, [location]);

  const fetchTransactionDetails = async (sessionId) => {
    try {
      const response = await axios.get(`${apiUrl}/transaction/${sessionId}`);
      setTransactionDetails(response.data);
    } catch (error) {
      console.error("Error fetching transaction details:", error);
    }
  };

  console.log(transactionDetails);

  return (
    <div className="payment-success-container">
      <h1>Payment Successful</h1>
      {transactionDetails && (
        <div>
          <p>Transaction ID: {transactionDetails.id}</p>
          <p>Amount: {transactionDetails.amount}</p>
          {/* Display other transaction details here */}
        </div>
      )}
    </div>
  );
};

export default Success;
