import React, { createContext, useContext, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
const AuthContext = createContext(null);

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [statusReady, setStatusReady] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState(null);

  const location = useLocation();

  const logOut = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/logout`, {
        method: "POST",
        credentials: "include", // important for cookies
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      setIsLoggedIn(false);
      setUser(null);
    } catch (error) {
      console.error("Logout failed:", error);
      // Handle logout errors here (e.g., show error message)
    }
  };

  const checkAuthStatus = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/user`, {
        method: "GET",
        credentials: "include",
      });
      if (!response.ok) {
        throw new Error("Failed to fetch auth status");
      }
      const data = await response.json();
      setIsLoggedIn(data.logged_in);
      setUser(data.user);
    } catch (error) {
      console.error("Error checking auth status:", error);
      setIsLoggedIn(false);
      setUser(null);
    }
    setStatusReady(true);
  };

  useEffect(() => {
    checkAuthStatus();
  }, [location]);

  return (
    <AuthContext.Provider
      value={{ isLoggedIn, user, setIsLoggedIn, setUser, logOut, statusReady }}
    >
      {children}
    </AuthContext.Provider>
  );
};
