import React, { useEffect } from "react";
import { useAuth } from "../../AuthContext";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import "./profile.css";

const apiUrl = process.env.REACT_APP_API_URL;

const Profile = () => {
  const { user, setUser, isLoggedIn, statusReady } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (statusReady && !isLoggedIn) navigate("/login");
  }, [isLoggedIn, statusReady, navigate]);

  const cancelSub = async () => {
    try {
      const response = await axios.put(
        `${apiUrl}/cancel_sub`,
        {},
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setUser(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="profile-container">
      <div className="profile-content">
        <h1>Profile</h1>
        <p>
          <strong>Username:</strong> {user?.username}
        </p>
        <p>
          <strong>Email:</strong> {user?.email}
        </p>
        <p>
          <strong>Tokens:</strong> {user?.tokens}
        </p>
        <p>
          <strong>Subscription Plan:</strong> {user?.subscription_plan}
        </p>
        <p>
          <strong>Subscription Status:</strong> {user?.subscription_status}
        </p>
        {user?.subscription_status === "active" && (
          <button className="cancel-sub-btn" onClick={cancelSub}>
            Cancel Subscription
          </button>
        )}
      </div>
    </div>
  );
};

export default Profile;
