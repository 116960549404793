import React, { useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import "./PublishUrlForm.css";
import { useAuth } from "../../AuthContext";

const apiUrl = process.env.REACT_APP_API_URL;

// FIXME: belongs somewhere else
const validURL = (str) => {
  var pattern = new RegExp(
    "^(https?:\\/\\/)" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator
  return !!pattern.test(str) || "Please enter a valid URL";
};

const PublishUrlForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm();

  const { user, setUser } = useAuth();

  const [response, setResponse] = useState("");

  const onSubmit = async (data) => {
    try {
      await axios.post(`${apiUrl}/publish_url`, data, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      });
      setUser({ tokens: user.tokens - 1, ...user });
      setResponse("URL successfully published");
    } catch (error) {
      console.error("Error publishing URL:", error);

      if (error.response?.status === 401 && error.response?.data?.redirect) {
        window.location.href = `${apiUrl}/start_auth`;
        return;
      } else if (error.response?.status === 403) {
        setResponse(
          error.response?.data?.message ||
            "Permission denied. You do not own the site you are trying to index or you are not logged in."
        );
        return;
      }
      setResponse(error.response?.data?.message || "Failed to publish URL");
    }
  };

  return (
    <div className="publish-form-container">
      <form onSubmit={handleSubmit(onSubmit)} className="publish-form">
        <input
          {...register("url", {
            required: "URL is required",
            validate: validURL,
          })}
          placeholder="https://example.com"
          type="text"
          className={`publish-input ${errors.url ? "input-error" : ""}`}
        />

        <button
          type="submit"
          disabled={isSubmitting}
          className="publish-button"
        >
          {isSubmitting ? "Publishing..." : "Publish URL"}
        </button>
      </form>
      {errors.url && <p className="error-message">{errors.url.message}</p>}
      {response && <div className="response-message">{response}</div>}
    </div>
  );
};

export default PublishUrlForm;
