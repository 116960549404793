import React from "react";
import { useAuth } from "../../AuthContext";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./home.css";

const apiUrl = process.env.REACT_APP_API_URL;

const Home = () => {
  const { user, isLoggedIn } = useAuth();
  const navigate = useNavigate();

  const startAuth = async () => {
    try {
      const response = await axios.get(`${apiUrl}/auth_status`, {
        withCredentials: true,
      });
      const { data } = response;
      if (data.authenticated) {
        navigate("/tools");
      } else {
        const authResponse = await axios.get(`${apiUrl}/start_auth`, {
          withCredentials: true,
        })
        window.location.href = authResponse?.data?.url
      }
    } catch (error) {
      console.error("Error starting authentication:", error);
    }
  };

  return (
    <div className="home">
      <h1 className="home-heading">Welcome{isLoggedIn && ` ${user.username}`}!</h1>
      {isLoggedIn ? (
        <button type="button" className="authenticate-button" onClick={startAuth}>
          Authenticate with Search Console
        </button>
      ) : (
        <p><a href="/login" >Log In</a> to get started.</p>
      )}
    </div>
  );
};

export default Home;