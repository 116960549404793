import React, { useEffect } from "react";
import PublishUrlForm from "../../components/PublishUrlForm";
import TokensForm from "../../components/TokensForm";
import { useAuth } from "../../AuthContext";
import { useNavigate } from "react-router-dom";
import "./tools.css";

const Tools = () => {
  const { user, isLoggedIn, statusReady } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (statusReady && !isLoggedIn) {
      navigate("/login");
    }
  }, [isLoggedIn, navigate, statusReady]);

  return (
    <div className="tools">
      <h1 className="tools-heading">Index Url Tool</h1>

      <p>Credits: {user?.tokens}</p>
      <PublishUrlForm />
      <TokensForm />
    </div>
  );
};

export default Tools;
