import React, { useState } from "react";
import { useAuth } from "../../AuthContext";
import axios from "axios";
import "./TokensForm.css"; // Importing the CSS

const apiUrl = process.env.REACT_APP_API_URL;

const TokensForm = () => {
  const { user } = useAuth();
  const [response, setResponse] = useState("");

  const handleBuyTokens = async () => {
    try {
      const response = await axios.put(
        `${apiUrl}/sub`,
        { userId: user.id }, // Adjust according to your API requirements
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log(response.data);

      // Redirect to Stripe's checkout page or handle response
      if (response.data && response.data.checkout_url) {
        window.location.href = response.data.checkout_url;
      } else {
        setResponse("Failed to initiate payment process");
      }
    } catch (error) {
      console.error(error);
      setResponse(error.response?.data?.message || "Failed to buy tokens");
    }
  };

  return (
    <div className="tokens-container">
      <button onClick={handleBuyTokens} className="buy-tokens-button">
        Buy Tokens
      </button>
      {response && <div className="response-message">{response}</div>}
    </div>
  );
};

export default TokensForm;
